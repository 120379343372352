<template>
  <div class="sidemenu">
    <div class="logo-sidemenu">
      <div class="company-title">
        Asia Catering
      </div>
    </div>
    <el-menu
    :default-active="activeIndex"
    class="sidemenu-menu"
    background-color="#592C98"
    text-color="#fff"
    active-text-color="#ffd04b">
    <div class="menu-item-wrapper" v-for="menuItem in menuItems">
      <router-link :to="menuItem.link" :key="menuItem.index" v-if="menuItem.type == 'menu-item'">
        <el-menu-item :index="menuItem.index">
          <i :class="menuItem.icon"></i>
          {{ $t(menuItem.title) }}
        </el-menu-item>
      </router-link>

      <a target="_blank" :href="menuItem.link" v-if="menuItem.type == 'external-menu-item'">
        <el-menu-item :index="menuItem.index">
          <i :class="menuItem.icon"></i>
          {{ $t(menuItem.title) }}
        </el-menu-item>
      </a>

      <el-submenu :index="menuItem.index" :key="menuItem.index" v-if="menuItem.type == 'submenu'">
        <template slot="title">
          <i :class="menuItem.icon"></i>
          <span slot="title">{{ $t(menuItem.title) }}</span>
        </template>
        <router-link :to="subItem.link" :index="subItem.index" :key="subItem.index" v-for="subItem in menuItem.submenu">
          <el-menu-item :index="subItem.index">
            {{ $t(subItem.title) }}
          </el-menu-item>
        </router-link>
      </el-submenu>
    </div>
  </el-menu>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Sidemenu',
  data(){
    return{
      activeIndex: '1',
      menuItems: [
        {
          'index': '1',
          'type': 'menu-item',
          'icon': 'el-icon-menu',
          'title': 'Dashboard',
          'link': '/dashboard',
        },
        {
          'index': '2',
          'type': 'menu-item',
          'icon': 'el-icon-s-order',
          'title': 'Order Management',
          'link': '/orders',
        },
        {
          'index': '3',
          'type': 'submenu',
          'icon': 'el-icon-s-grid',
          'title': 'Store management',
          'submenu': [
            {
              'index': '3-1',
              'title': 'Seats (Floor Plan)',
              'link': '/seats',
            },
            {
              'index': '3-2',
              'title': 'Seats (List)',
              'link': '/seats/list',
            },
          ]
        },
        {
          'index': '4',
          'type': 'submenu',
          'icon': 'el-icon-notebook-1',
          'title': 'Menu Management',
          'submenu': [
            {
              'index': '4-1',
              'title': 'Meals',
              'link': '/meals',
            },
            {
              'index': '4-2',
              'title': 'Categories',
              'link': '/categories',
            },
            {
              'index': '4-3',
              'title': 'Set Meals',
              'link': '/set-meals',
            },
          ],
        },
        {
          'index': '5',
          'type': 'submenu',
          'icon': 'el-icon-s-cooperation',
          'title': 'Marketing Management',
          'submenu': [
            {
              'index': '5-1',
              'title': 'Time Period',
              'link': '/time-period',
            },
            {
              'index': '5-2',
              'title': 'Special Day',
              'link': '/special-day',
            },
          ],
        },
        {
          'index': '6',
          'type': 'submenu',
          'icon': 'el-icon-house',
          'title': 'Inventory Management',
          'submenu': [
            {
              'index': '6-1',
              'title': 'Inventory',
              'link': '/inventory',
            },
            {
              'index': '6-2',
              'title': 'Vendor',
              'link': '/vendor',
            },
            {
              'index': '6-3',
              'title': 'Ordering Records',
              'link': '/ordering-records',
            },
            {
              'index': '6-4',
              'title': 'Transfer Management',
              'link': '/transfer',
            }
          ],
        },
        {
          'index': '7',
          'type': 'submenu',
          'icon': 'el-icon-s-custom',
          'title': 'Member Management',
          'submenu': [
            {
              'index': '7-1',
              'title': 'Members',
              'link': '/members',
            },
            {
              'index': '7-2',
              'title': 'Member Level',
              'link': '/member/level',
            },
            {
              'index': '7-3',
              'title': 'Member News',
              'link': '/member/news',
            },
          ],
        },
        {
          'index': '8',
          'type': 'submenu',
          'icon': 'el-icon-shopping-bag-1',
          'title': 'Delivery Management',
          'submenu': [
          {
              'index': '8-1',
              'title': 'Delivery Orders',
              'link': '/delivery/orders',
            },
            {
              'index': '8-2',
              'title': 'Delivery Staffs',
              'link': '/delivery/staffs',
            },
            {
              'index': '8-3',
              'title': 'Delivery Customer',
              'link': '/delivery/customer',
            },
          ],
        },
        {
          'index': '9',
          'type': 'external-menu-item',
          'icon': 'el-icon-s-shop',
          'title': 'POS Management',
          'link': 'https://asiacateringgroup.com.hk',
        },
        {
          'index': '10',
          'type': 'submenu',
          'icon': 'el-icon-s-tools',
          'title': 'Cloud Backend Management',
          'submenu': [
            {
              'index': '10-1',
              'title': 'Brands Management',
              'link': '/brands',
            },
            {
              'index': '10-2',
              'title': 'Restaurants Management',
              'link': '/restaurants',
            },
            {
              'index': '10-3',
              'title': '雲端數據備份 / 匯入 / 匯出',
              'link': '/settings/backup',
            },
            {
              'index': '10-4',
              'title': 'Staff Record',
              'link': '/settings/staff-record',
            },
            {
              'index': '10-5',
              'title': 'Realtime Report',
              'link': '/report',
            },
            {
              'index': '10-6',
              'title': 'Account Settings',
              'link': '/settings/account',
            },
            {
              'index': '10-7',
              'title': 'System Settings',
              'link': '/settings/system',
            },
          ],
        },
      ],
    }
  },
  mounted(){
    this.checkActive();
  },
  methods:{
    //Check current active menu item
    checkActive(){
      let activeIndex = '';
      const currentPath = window.location.pathname;
      for(let i = 0; i < this.menuItems.length; i++){
        const menuItem = this.menuItems[i];
        //If the menu item do not had submenu
        if(menuItem.type === 'menu-item'){
          if(menuItem.link === currentPath){
            activeIndex = menuItem.index;
            return;
          }
        }else{
          //If the menu item had submenu
          if(menuItem === 'submenu'){
            menuItem['submenu'].forEach(function(item){
            if(item.link === currentPath){
              activeIndex = item.index;
              return;
            }
          });
        }
          }
      }
      this.activeIndex = activeIndex;
    },
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style scoped lang="scss">
.logo-sidemenu{
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: 25px;
  color: white;
  display: flex;
  align-items: center;
  img{
    width: 30%;
  }
}
.sidemenu{
  width: 270px;
  background: #592C98;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh;

  a{
    color: white;
    &:hover{
      text-decoration: none;
    }
  }

  .sidemenu-menu{
    border-right: 0;
  }
  .el-submenu__title i{
    color: white;
  }
  .el-menu-item i{
    color: white;
  }
}
</style>
